import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { unregister as unregisterServiceWorker } from './registerServiceWorker'
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { ParallaxProvider } from "react-scroll-parallax";

ReactDOM.render((
    <BrowserRouter>
        <ScrollToTop>
            <ParallaxProvider>
                <App />
            </ParallaxProvider>
        </ScrollToTop>
    </BrowserRouter>
), document.getElementById('root'));
unregisterServiceWorker();
