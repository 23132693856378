// @flow

import React, { useEffect, useState } from "react";
import { Parallax } from "react-scroll-parallax";
import styles from "./NewPracticeSection.module.css";
import Image from "./Image";
import { ProjectsContext } from "../../ProjectsContext";

const NewPracticeSection = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const isObjectEmpty = (objectName) => {
    return (
      objectName &&
      Object.keys(objectName).length === 0 &&
      objectName.constructor === Object
    );
  };

  const setOffsetYMax = (windowWidth) => {
    console.log(windowWidth <= 490 ? 0 : 20);
    return windowWidth <= 490 ? 0 : 20;
  };
  const setOffsetYMin = (windowWidth) => {
    console.log(windowWidth <= 490 ? 0 : -30);
    return windowWidth <= 490 ? 0 : -30;
  };

  return (
    <ProjectsContext.Consumer>
      {({ practice }) =>
        isObjectEmpty(practice) ? (
          ""
        ) : (
          <div className={styles.new_practice_section}>
            <div className={styles.row}>
              <div className={styles.full_bleed}>
                <Parallax
                  className={styles.cube_para}
                  y={[setOffsetYMin(width), setOffsetYMax(width)]}
                  tagOuter="figure"
                >
                  <Image src={practice?.acf["parallax_image_#1"]} />
                </Parallax>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.cube}>
                <div
                  className={`${styles.cube_content} ${styles.padding_right}`}
                >
                  <h2 className="text-white reg light">Who we are</h2>
                  <p className="text-grey reg lighter">
                    {practice?.acf.who_we_are}
                  </p>
                </div>
              </div>
              <div className={`${styles.cube} ${styles.img_cube}`}>
                <div className={styles.cube_para} tagOuter="figure">
                  <Image
                    y={[-20, 10]}
                    src={practice?.acf["parallax_image_#2"]}
                  />
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={`${styles.cube} ${styles.img_cube}`}>
                <div className={styles.cube_para} tagOuter="figure">
                  <Image
                    y={[-20, 10]}
                    src={practice?.acf["parallax_image_#3"]}
                  />
                </div>
              </div>
              <div className={styles.cube}>
                <div
                  className={`${styles.cube_content} ${styles.padding_left}`}
                >
                  <h2 className="text-white reg light">What we do</h2>
                  <p className="text-grey reg lighter">
                    {practice?.acf.what_we_do}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.full_bleed}>
                <Parallax
                  className={styles.cube_para}
                  y={[-10, 30]}
                  tagOuter="figure"
                >
                  <Image src={practice?.acf["parallax_image_#4"]} />
                </Parallax>
              </div>
            </div>
          </div>
        )
      }
    </ProjectsContext.Consumer>
  );
};

export default NewPracticeSection;
