import React, { Component } from "react";
import { Container, Row, Col } from "react-grid-system";
import "../../App.css";
import "./ContactPage.css";
import Clock from "react-live-clock";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import HoverLink from "../HoverLink/HoverLink";

import "../FlickSlider/FlickSlider.css";
import ContactSlider from "../ContactSlider/ContactSlider";
import CSSAnimate from "../CSSAnimate/CSSAnimate";
import { ProjectsContext } from "../../ProjectsContext";

class ContactPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        phoneNumber: "",
        fullName: "",
        email: "",
        company: "",
        message: "",
      },
    };

    this.handleChange = this.handleChange;
    this.handleSubmit = this.handleSubmit;
  }

  componentDidMount() {
    this.styleBlack();
  }

  styleBlack = () => {
    document.querySelector("header").classList.remove("white-bg");
    document.querySelector("header").classList.add("black-bg");
  };

  handleChange = (event) => {
    // console.log(event.currentTarget)
    this.setState({ [`${event.target.formName}`]: event.target.value });
  };

  handleSubmit = (event) => {
    alert("A name was submitted: " + this.state.value);
    event.preventDefault();
  };

  render() {
    return (
      <ProjectsContext.Consumer>
        {({ contact_images }) => (
          <section className="transition-page contact-page">
            <Container
              fluid={true}
              className="container"
              style={{ padding: 0, background: "#000" }}
            >
              <CSSAnimate key="i" delay="1000">
                <h1
                  className="text-white lighter reg lets-work-together"
                  style={{ padding: "7rem 0 0 0", width: "75%" }}
                >
                  Let's work together.
                </h1>
              </CSSAnimate>
              <br />
              <br />
              <CSSAnimate key="j" delay="1200">
                <h1
                  className="text-grey lighter reg  lets-work-together"
                  style={{ padding: "0 0 7rem 0" }}
                >
                  Email us at{" "}
                  <a href="mailto:work@piscatello.com">
                    <HoverLink grey-grey="true">work@piscatello.com</HoverLink>
                  </a>{" "}
                  or call{" "}
                  <a href="tele:+1 212 502 4734">
                    <HoverLink grey-grey="true">+1 212 502 4734</HoverLink>
                  </a>{" "}
                  to learn how we can become an extension of your team.
                </h1>
              </CSSAnimate>
            </Container>
            <div className="contact-banner padding-btm-5">
              <ContactSlider />
            </div>
            <div
              style={{
                backgroundColor: "#000",
                paddingTop: "0",
                paddingBottom: "10rem",
                borderBottom: "1px solid #DDD",
              }}
            >
              <Container
                fluid={true}
                className="container"
                style={{ padding: "0 0 0 0" }}
              >
                <div className="information-grid" style={{ paddingTop: 0 }}>
                  <div className="info-block text-white">
                    <h3 className="reg text-bold">
                      {contact_images.acf.contact_list_1.contact_list_1_heading}
                    </h3>
                    <p className="light text-grey padding-top-2">
                      {contact_images.acf.contact_list_1.contact_list_1_address}
                      <br />
                      <br />
                      {contact_images.acf.contact_list_1.contact_list_1_phone}
                      <div className="arrow-hover">
                        <a
                          target="_blank"
                          href={
                            contact_images.acf.contact_list_1
                              .contact_list_1_link
                          }
                        >
                          <HoverLink grey-grey="true">
                            {
                              contact_images.acf.contact_list_1
                                .contact_list_1_link_text
                            }
                          </HoverLink>
                        </a>
                        <span className="link-arrow" />
                      </div>
                    </p>
                  </div>
                  <div className="info-block">
                    <h3 className="reg text-white">
                      {contact_images.acf.contact_list_2.contact_list_2_heading}
                    </h3>
                    <p className="light padding-top-2 text-grey">
                      {contact_images.acf.contact_list_2.contact_list_2_item_1_heading}
                    </p>
                    <p className="light text-grey">
                      <a
                        href={
                          contact_images.acf.contact_list_2
                            .contact_list_2_item_1_text_email
                        }
                      >
                        <HoverLink grey-grey="true">
                          {
                            contact_images.acf.contact_list_2
                              .contact_list_2_item_1_text
                          }
                        </HoverLink>
                      </a>
                    </p>
                    <p className="light padding-top-2 text-grey">
                      {contact_images.acf.contact_list_2.contact_list_2_item_2_heading}
                    </p>
                    <p className="light text-grey">
                      <Link to="/careers">
                        <HoverLink grey-grey="true">
                          {
                            contact_images.acf.contact_list_2
                              .contact_list_2_item_2_text
                          }
                        </HoverLink>
                      </Link>
                    </p>
                  </div>
                  <div className="info-block">
                    <h3 className="reg text-white">
                      {contact_images.acf.contact_list_3.contact_list_3_heading}
                    </h3>
                    <p className="light padding-top-2 arrow-hover text-grey">
                      <a
                        href={
                          contact_images.acf.contact_list_3
                            .contact_list_3_item_1_link
                        }
                        target="_blank"
                        className="text-black"
                      >
                        <HoverLink grey-grey="true">
                          {
                            contact_images.acf.contact_list_3
                              .contact_list_3_item_1
                          }
                        </HoverLink>
                        <span className="link-arrow white" />
                      </a>
                    </p>
                    <p className="light text-grey">
                      <a
                        href={
                          contact_images.acf.contact_list_3
                            .contact_list_3_item_2_link
                        }
                        target="_blank"
                        className="text-black arrow-hover"
                      >
                        <HoverLink grey-grey="true">
                          {
                            contact_images.acf.contact_list_3
                              .contact_list_3_item_2
                          }
                        </HoverLink>
                        <span className="link-arrow white" />
                      </a>
                    </p>
                    <p className="light arrow-hover text-grey">
                      <a
                        href={
                          contact_images.acf.contact_list_3
                            .contact_list_3_item_3_link
                        }
                        target="_blank"
                        className="text-black"
                      >
                        <HoverLink grey-grey="true">
                          {
                            contact_images.acf.contact_list_3
                              .contact_list_3_item_3
                          }
                        </HoverLink>
                        <span className="link-arrow white" />
                      </a>
                    </p>
                  </div>
                  <div className="info-block">
                    <h3 className="reg text-white text-bold">
                      <Clock
                        format={"hh"}
                        ticking={true}
                        timezone={"US/Eastern"}
                      />
                      <span className="blink">:</span>
                      <Clock
                        format={"mma"}
                        ticking={true}
                        timezone={"US/Eastern"}
                      />
                    </h3>
                    <p className="light text-grey  padding-top-2">
                      {contact_images.acf.contact_list_4_content}
                    </p>
                  </div>
                </div>
              </Container>
            </div>
          </section>
        )}
      </ProjectsContext.Consumer>
    );
  }
}

export default ContactPage;
