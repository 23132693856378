import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import ProjectContainer from "../ProjectContainer/ProjectContainer";
import Filter from "../Filter/Filter";
import { WorkContext, Pages } from "./Pages";
import { ProjectsContext } from "../../ProjectsContext";
import CSSAnimate from "../CSSAnimate/CSSAnimate";
/* @CSS */

import "../../App.css";
import "./WorkPage.css";

const WorkPage = () => {
  let isObjectEmpty = (objectName) => {
    return (
      objectName &&
      Object.keys(objectName).length === 0 &&
      objectName.constructor === Object
    );
  };
  const filterValue = useContext(ProjectsContext);
  // console.log(filterValue)
  const [filter, setFilter] = useState(filterValue.filters.acf["all projects"]);
  const [clicked, setClicked] = useState(false);

  const setFilterKey = (filter) => {
    setTimeout(() => {
      setFilter(filterValue.filters.acf[filter]);
      if (clicked) setClicked(false);
      setClicked(true);
      setTimeout(() => {
        setClicked(false);
      }, 1000);
    }, 600);
  };

  const contextState = {
    setFilter: setFilterKey,
    filter: filter,
  };

  const renderProjects = (projects) => {
    if (filter && filter.url !== "all") {
      let tmp = projects;
      projects = [];
      projects = tmp.filter((el) => {
        const categories = el._embedded["wp:term"][0];
        let result = false;
        for (let i = 0; i < categories.length; i++) {
          if (categories[i].name === filter.title) {
            result = true;
            break;
          }
        }
        return result;
      });
    }

    const template = projects.map((el, i) => (
      <ProjectContainer
        project={el}
        filter={filter.title}
        index={i}
        key={`${filter.title}-${el.id}`}
      />
    ));

    return template;
  };

  return (
    <section className="transition-page" id="page-work">
      <Container
        fluid={true}
        className="container"
        style={{ padding: 0, background: "#FFF" }}
      >
        <Row className="top-text-mobile">
          <Col xs={12} sm={10} md={9} lg={9}>
            <CSSAnimate key="i" delay="1000">
              <h1
                className={
                  clicked
                    ? "reg lighter text-black animate clicked"
                    : "reg lighter text-black animate"
                }
              >
                {filter.description}
              </h1>
            </CSSAnimate>
          </Col>
        </Row>
      </Container>
      <section id="work-list">
        <div id="project-list">
          <Container fluid={true} className="container" style={{ padding: 0 }}>
            {!isObjectEmpty(filter) && !isObjectEmpty(contextState) ? (
              <WorkContext.Provider value={contextState}>
                <Filter />
              </WorkContext.Provider>
            ) : (
              ""
            )}

            {isObjectEmpty(filter) ? (
              ""
            ) : (
              <ProjectsContext.Consumer>
                {({ projects }) => (
                  <div className="project-grid">{renderProjects(projects)}</div>
                )}
              </ProjectsContext.Consumer>
            )}
          </Container>
        </div>
      </section>
    </section>
  );
};

export { WorkContext };
export default WorkPage;
