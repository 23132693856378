import React, { Component } from 'react';

class CareerPageSingle extends Component {

    render() {
        return(
            <div id="careersingle">
                
            </div>
        )
    }
}

export default CareerPageSingle;